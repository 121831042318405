import React from 'react'
import axios from 'axios'
import { message } from 'antd'
import { Msg } from 'utils'
import { getStorage } from 'utils/storage'
import { ERROR_CODE } from 'utils/type'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
/**
 * 封装axios请求
 * axios.create 创建一个实例instance
 * instance.interceptors.request.use 拦截请求
 * instance.interceptors.response.use 拦截响应
 */
//  window.location.host
// let baseURL = process.env.NODE_ENV === 'development' ? 'http://testapi.mittrchina.com/' : 'https://apii.mittrchina.com'
// let baseURL = window.location.host === 'm.applytxmdv.com' ? 'https://apii.mittrchina.com' : 'http://testapi.mittrchina.com/'
let baseURL = process.env.REACT_APP_BASE_URL
const instance = axios.create({
    // 测试地址：'http://testapi.mittrchina.com/' 正式接口：http://dataapi.sciphi.cn
    baseURL,
    // withCredentials: true, // send cookies when cross-domain requests
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
    transformRequest: [function (data, headers) {
        let ret = ''
        for (const it in data) {
            if (ret !== '') {ret += '&'}
            ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it])
        }
        return ret
    }],
    // timeout: 10000 // 请求超时时间
})
let timer

// 拦截请求
/**
 * GET传参请求数据必须带params字段，例params：{id:1}
 */
instance.interceptors.request.use(config => {
    if (config.method.toUpperCase() === "POST"){
        
    } else if (config.method.toUpperCase() === "GET"){
        
        config.params = {...config.params}
    }
    const token = getStorage('token')
    if (token) {
        config.headers.common['token'] = token
    }
    return config
},
error => {
    Promise.reject(error)
})
// 拦截响应
instance.interceptors.response.use(response => {
    // code !==10000 的返回处理 错误代码处理一一对应
    if (response.data.code === 20001 || response.data.code === 20019) {
        // token失效 、当前用户使用期限已过
        Msg('warning',ERROR_CODE[`${response.data.code}`])
        response.data.code === 20001 && setTimeout(()=>{
            window.location.href = '/login'
        },1500)
    } else if (response.data.code !== 10000 && response.data.code !== 20013 && response.data.message) {
        Msg('warning',ERROR_CODE[`${response.data.code}`])
    }
    return response.data
},
err => {
    clearTimeout(timer)
    timer = setTimeout(() => {
        if (err && err.response) {
            switch (err.response.status) {
            case 400:
                message.error('错误请求')
                break
            case 401:
                localStorage.clear()
                message.error('登录信息过期或未授权，请重新登录！')
                break
            case 403:
                message.error('拒绝访问！')
                window.location.href = '/error403'
                break
            case 404:
                message.error('请求错误,未找到该资源！')
                window.location.href = '/error404'
                break
            case 500:
                message.error('服务器出问题了，请稍后再试！')
                window.location.href = '/error500'
                break
            default:
                message.err(`连接错误 ${err.response.status}！`)
                break
            }
        } else {
            Msg('error','您当前请求过多频繁，请稍后刷新重试！')
            // window.location.href = '/login'
        }
    }, 200) // 200 毫秒内重复报错则只提示一次！
    return Promise.reject(err)
})

export default instance


